<template>
   <div :class="['container-privacy', { 'dark-theme': isDarkTheme }]">
      <SWNavButton @click="$router.push('/login')" class="btn-nav-ragister" :size="'small'"
         >Back</SWNavButton
      >
      <div class="text-content-privacy">
         <div class="title-privacy"><p>Terms and conditions of use</p></div>
         <div class="paragraf-privacy">
            <p>
               <strong>This service includes subscriptions that automatically renew. </strong> Please read these terms
               and conditions of use (the "Terms") carefully (in particular, Section 6 "subscription fees and payment")
               before starting a trial or completing a purchase for our app’s auto-renewing subscription service.
               <strong> To avoid being charged </strong> you must <strong> affirmatively cancel </strong> your
               subscription
               <strong> at least 24 hours before the end of the free trial or then-current subscription period.</strong>
            </p>
            <p>
               If you are unsure how to cancel a subscription or a free trial, please visit our website for
               instructions. Deleting the app does not cancel your subscriptions and trials. We also aim to provide
               information about our subscription policies at or near the point of purchase. Please review these
               policies prior to making purchases. You may wish to make a printscreen of this information for your
               reference.
            </p>
            <p class="toLowerCase">
               PLEASE NOTE: THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION IN SECTION 13 THAT AFFECTS YOUR RIGHTS
               UNDER THESE TERMS. THE ARBITRATION PROVISION REQUIRES THAT DISPUTES BE RESOLVED IN ARBITRATION ON AN
               INDIVIDUAL BASIS. IN ARBITRATION, THERE IS NO JUDGE OR JURY AND THERE IS LESS APPELLATE REVIEW THAN IN
               COURT. EXCEPT AS SPECIFIED BELOW IN SECTION 13, UNLESS YOU OPT OUT WITHIN 30 DAYS OF FIRST USE OF OUR
               SERVICE AS PROVIDED FOR IN SECTION 13, ARBITRATION IS THE EXCLUSIVE VENUE FOR ANY AND ALL DISPUTES AND IS
               MANDATORY.
            </p>
            <p class="toLowerCase">
               FURTHERMORE, THESE TERMS CONTAIN IMPORTANT DISCLAIMERS IN (SECTION 2), DISCLAIMERS OF WARRANTIES (SECTION
               9), LIMITATION OF LIABILITY (SECTION 10) AND CLASS ACTION WAIVER (SECTION 13).
            </p>
         </div>
         <div class="paragraf-privacy">
            <ul class="contents-list">
               <p class="content-link">Contents:</p>
               <li class="contents-list-item">
                  <a href="#item-content-1" class="contents-list-link">1. Acceptance of terms</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-1' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >1.1. Acceptance of terms</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-2" class="contents-list-link">2. Important disclaimers</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-2' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >2.2. Important disclaimers</router-link 
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-3" class="contents-list-link">3. Profile registration</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-3' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >3.3. Profile registration</router-link
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-4" class="contents-list-link">4. Service</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-4' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >4.4. Service</router-link
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-5" class="contents-list-link">5. App stores, third party ads, other users</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-5' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >5.5. App stores, third party ads, other users</router-link
                  >-->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-6" class="contents-list-link">6. Subscription fees and payment</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-6' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >6.6. Subscription fees and payment</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-7" class="contents-list-link">7. User representations and restrictions</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-7' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >7.7. User representations and restrictions</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-8" class="contents-list-link">8. Additional disclaimer of warranties</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-8' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >8.8. Additional disclaimer of warranties</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-9" class="contents-list-link">9. Limitation of liability</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-9' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >9.9. Limitation of liability</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-10" class="contents-list-link">10. Indemnity</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-10' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >10.10. Indemnity</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-11" class="contents-list-link">11. International use</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-11' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >11.11. International use</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-12" class="contents-list-link">12. Mandatory binding arbitration and class action waiver</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-12' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >12.12. Mandatory binding arbitration and class action waiver</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-13" class="contents-list-link">13. Governing law</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-13' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >13.13. Governing law</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-14" class="contents-list-link">14. Miscellaneous provisions</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-14' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >14.14. Miscellaneous provisions</router-link
                  > -->
               </li>
               <li class="contents-list-item">
                  <a href="#item-content-15" class="contents-list-link">15. Contact</a>
<!--                   <router-link
                     :to="{ hash: '#item-content-15' }"
                     @click.native="anchorHashCheck"
                     class="contents-list-link"
                     >15.15. Contact</router-link
                  > -->
               </li>
            </ul>
         </div>
         <div class="paragraf-privacy" id="item-content-1">
            <p class="title-paragraf">1. ACCEPTANCE OF TERMS</p>
            <p>
               <strong> 1.1</strong> The provisions of the “Terms” govern the relationship between you and FUNDACJA
               FIRMA DLA KAŻDEGO with its registered office at ul. LWOWSKA, nr 5, lok. 15, miejsc. WARSZAWA, kod 00­660,
               poczta WARSZAWA, kraj POLSKA, entered in the Commercial register of the District Court in Warsaw, Poland,
               12th Commercial Division of the National Court Register under the number KRS 0000569410, Tax
               Identification Number (NIP): 5252625624, statistical number (REGON): 362170026 and by an organized part
               of the FUNDACJA FIRMA DLA KAŻDEGO separated within the Foundation, represented by sleep-well.coach,
               (“we”, “us”, “our” or the “Company”) regarding your use of the Company’s mobile applications, websites
               and related services (the “App” or “Service”), including all information, text, graphics, software, and
               services, available for your use (the “Content”).
            </p>
            <p class="toLowerCase">
               <strong>1.2</strong> These Terms establish a legally binding contractual relationship between you and the
               Company. For this reason, PLEASE READ THE TERMS CAREFULLY BEFORE USING THE SERVICE.
            </p>
            <p>
               <strong> 1.3.</strong> Please review also our Privacy Policy. The terms of the Privacy Policy and other
               supplemental terms, policies or documents that may be posted on the Service from time to time are hereby
               expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes
               or modifications to these Terms at any time and for any reason.
            </p>
            <p>
               <strong> 1.4.</strong> Any translation from English version is provided for your convenience only. In the
               event of any difference in meaning or interpretation between the English language version of these Terms
               available at https://sleep-well.coach/terms, and any translation, the English language version will
               prevail. The original English text shall be the sole legally binding version.
            </p>

            <p>
               <strong> 1.5. </strong> We may change these Terms on this page of the Website. We may provide you with
               notice about some critical changes, for example by email or by posting notifications on the Service, but
               are not obliged to do so in every case. Any other changes will be notified to you only by updating the
               "Last updated" date of these Terms and you waive any right to receive specific notice of each such
               change. If you don’t agree to the changes, you should stop using the Service. Use of the Service after
               any changes to these Terms are made means that you accept such changes.
            </p>
            <p class="toLowerCase">
               <strong> 1.6. </strong> IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, OR IF YOU ARE NOT ELIGIBLE OR
               AUTHORIZED TO BE BOUND BY THESE TERMS, THEN DO NOT DOWNLOAD THE APP OR OTHERWISE ACCESS OR USE THE
               SERVICE.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-2">
            <p class="title-paragraf">2. IMPORTANT DISCLAIMERS</p>
            <p class="toLowerCase">
               <strong> 2.1</strong> THE COMPANY DOES NOT OFFER OR PROVIDE ANY KIND OF MEDICAL ADVICE, HEALTH INSURANCE
               OR OTHER HEALTHCARE SERVICE, INCLUDING WITHOUT LIMITATION, ANY COUNSELING, TESTING, EVALUATION,
               PRESCRIPTION, PROCEDURE OR THERAPY RELATED TO EXERCISE, NUTRITION, WEIGHT LOSS OR WELLNESS, MENTAL HEALTH
               OR RELATED TO THE AVOIDANCE, PREVENTION, DIAGNOSIS OR TREATMENT OF ANY INJURY, ILLNESS, DISEASE OR
               CONDITION (COLLECTIVELY, "HEALTHCARE SERVICES").
            </p>
            <p class="toLowerCase">
               <strong>2.2</strong> THE SERVICE MAY NOT BE APPROPRIATE FOR ALL PERSONS AND IS NOT A SUBSTITUTE FOR
               PROFESSIONAL HEALTHCARE SERVICES. THE SERVICE IS INTENDED ONLY AS A TOOL, WHICH MAY BE USEFUL IN
               ACHIEVING YOUR OVERALL HEALTH, FITNESS AND WELNESS GOALS. YOU ACKNOWLEDGE THAT YOUR DIET AND EXERCISE
               ACTIVITIES INVOLVE RISKS, WHICH MAY INVOLVE RISK OF BODILY INJURY OR DEATH, AND THAT YOU ASSUME THOSE
               RISKS. BEFORE ACCESSING OR USING THE SERVICE, AND AGREE TO RELEASE AND DISCHARGE THE COMPANY FROM ANY AND
               ALL ACTION, KNOWN OR UNKNOWN, ARISING OUT OF YOUR USE OF THE SERVICE.
            </p>
            <p class="toLowerCase">
               <strong> 2.3.</strong> YOU SHOULD CONSULT WITH YOUR PHYSICIAN OR OTHER QUALIFIED HEALTHCARE PROFESSIONAL
               TO DETERMINE WHETHER THE SERVICE WOULD BE SAFE AND EFFECTIVE FOR YOU. YOU ARE EXPRESSLY PROHIBITED FROM
               ACCESSING OR USING THE SERVICE AGAINST MEDICAL ADVICE OR IF DOING SO MIGHT POSE ANY HEALTH RISK. IN THIS
               CONTEXT, YOU ACKNOWLEDGE THAT YOU TAKE FULL RESPONSIBILITY FOR YOUR HEALTH, LIFE AND WELL-BEING, AS WELL
               AS THE HEALTH, LIVES AND WELL-BEING OF YOUR FAMILY AND CHILDREN (BORN AND UNBORN, AS APPLICABLE), AND ALL
               DECISIONS NOW OR IN THE FUTURE.
            </p>
            <p class="toLowerCase">
               <strong> 2.4.</strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY AGREE THAT WE ARE
               NOT PROVIDING MEDICAL ADVICE VIA THE SERVICE. ALL CONTENT PROVIDED THROUGH THE SERVICE, WHETHER PROVIDED
               BY US OR THIRD PARTIES (EVEN IF THEY ARE CLAIMING TO BE A DOCTOR) IS NOT INTENDED TO BE AND SHOULD NOT BE
               USED IN PLACE OF (I) THE ADVICE OF YOUR PHYSICIAN OR OTHER PROFESSIONALS, (II) A VISIT, CALL OR
               CONSULTATION WITH YOUR PHYSICIAN OR OTHER HEALTHCARE PROFESSIONALS, OR (III) INFORMATION CONTAINED ON OR
               IN ANY PRODUCT PACKAGING OR LABEL. WE ARE NOT RESPONSIBLE FOR ANY HEALTH PROBLEMS THAT MAY RESULT FROM
               TRAINING PROGRAMS, CONSULTATIONS, PRODUCTS, OR EVENTS YOU LEARN ABOUT THROUGH THE SERVICE. SHOULD YOU
               HAVE ANY HEALTH RELATED QUESTIONS, PLEASE CALL OR SEE YOUR PHYSICIAN OR OTHER HEALTHCARE PROFESSIONAL
               PROMPTLY. IF YOU HAVE AN EMERGENCY, CALL YOUR PHYSICIAN OR YOUR LOCAL EMERGENCY SERVICES IMMEDIATELY.
            </p>

            <p class="toLowerCase">
               <strong> 2.5. </strong> YOUR USE OF THE SERVICE DOES NOT CONSTITUTE OR CREATE A DOCTOR-PATIENT,
               THERAPIST-PATIENT OR OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND THE COMPANY.
            </p>
            <p class="toLowerCase">
               <strong> 2.6. </strong> THE COMPANY DOES NOT ASSUME ANY LIABILITY FOR INACCURACIES OR MISSTATEMENTS ABOUT
               FOOD RECIPES, EXERCICES OR OTHER CONTENT ON THE SERVICE. YOU SHOULD CAREFULLY READ ALL INFORMATION
               PROVIDED BY THE MANUFACTURERS OF THE FOOD PRODUCTS, WHETHER ONLINE OR ON THE ACTUAL PRODUCT PACKAGING AND
               LABELS, INCLUDING NUTRIENT CONTENT, INGREDIENTS, FOOD ALLERGEN AND CONTACT INFORMATION, AND HEALTH
               CLAIMS, BEFORE USING OR CONSUMING A PRODUCT. FOR ADDITIONAL INFORMATION ABOUT A FOOD PRODUCT, PLEASE
               CONTACT THE MANUFACTURER DIRECTLY.
            </p>
            <p class="toLowerCase">
               <strong> 2.7. </strong> WE MAKE NO GUARANTEES CONCERNING THE LEVEL OF SUCCESS YOU MAY EXPERIENCE, AND YOU
               ACCEPT THE RISK THAT RESULTS WILL DIFFER FOR EACH INDIVIDUAL. THE TESTIMONIALS AND EXAMPLES THAT MAY BE
               PROVIDED ON THE SERVICE ARE EXCEPTIONAL RESULTS, WHICH DO NOT APPLY TO AN AVERAGE PERSON, AND ARE NOT
               INTENDED TO REPRESENT OR GUARANTEE THAT ANYONE WILL ACHIEVE THE SAME OR SIMILAR RESULTS. THERE IS NO
               ASSURANCE THAT EXAMPLES OF PAST FITNESS RESULTS CAN BE DUPLICATED IN THE FUTURE. WE CANNOT GUARANTEE YOUR
               FUTURE RESULTS AND/OR SUCCESS. NOR CAN WE GUARANTEE THAT YOU MAINTAIN THE RESULTS YOU EXPERIENCE IF YOU
               DO NOT CONTINUE FOLLOWING OUR PROGRAMS.
            </p>
            <p class="toLowerCase">
               <strong> 2.6. </strong> EACH INDIVIDUAL'S HEALTH, FITNESS, AND NUTRITION SUCCESS DEPENDS ON HIS OR HER
               BACKGROUND, DEDICATION, DESIRE, AND MOTIVATION. AS WITH ANY HEALTH-RELATED PROGRAM OR SERVICE, YOUR
               RESULTS MAY VARY, AND WILL BE BASED ON MANY VARIABLES, INCLUDING BUT NOT LIMITED TO, YOUR INDIVIDUAL
               CAPACITY, LIFE EXPERIENCE, UNIQUE HEALTH AND GENETIC PROFILE, STARTING POINT, EXPERTISE, AND LEVEL OF
               COMMITMENT. THE USE OF THE SERVICE SHOULD BE BASED ON YOUR OWN DUE DILIGENCE AND YOU AGREE THAT THE
               COMPANY IS NOT LIABLE FOR ANY SUCCESS OR FAILURE OF YOUR PHYSIQUE THAT IS DIRECTLY OR INDIRECTLY RELATED
               TO THE PURCHASE AND USE OF THE SERVICE.
            </p>
            <p class="toLowerCase">
               <strong> 2.8. </strong> IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMERS IN THESE TERMS, THE COMPANY
               DISCLAIMS ANY LIABILITY OR LOSS IN CONNECTION WITH THE CONTENT PROVIDED ON THE SERVICE. YOU ARE
               ENCOURAGED TO CONSULT WITH YOUR DOCTOR AND OTHER RELEVANT PROFESSIONALS WITH REGARD TO THE INFORMATION
               CONTAINED ON OR ACCESSED THROUGH THE SERVICE.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-3">
            <p class="title-paragraf">3. PROFILE REGISTRATION</p>
            <p>
               <strong> 3.1</strong> In order to use certain features of the Service, you may need to register your
               profile ("Profile") and provide certain information about yourself as prompted by the registration form.
            </p>
            <p>
               <strong>3.2</strong> If you register the Profile, you represent and warrant to the Company that: (i) all
               required registration information you submit is truthful and accurate; (ii) you will maintain the
               accuracy of such information; and (iii) your use of the Service does not violate any applicable law or
               regulation or these Terms. Otherwise, the Service may not operate correctly, and we may not be able to
               contact you with important notices.
            </p>
            <p>
               <strong> 3.3.</strong> . The Service is not intended to be used by individuals under age of 15. You
               hereby represent and warrant to the Company that you meet the foregoing qualification. All users who are
               minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission
               of, and be directly supervised by, their parent or guardian to use the Service. If you are a minor, you
               must have your parent or guardian read and agree to these Terms prior to you using the Service.
            </p>
            <p>
               <strong> 3.4.</strong> The Company reserves the right to suspend or terminate your Profile, or your
               access to the Service, with or without notice to you, in the event that you breach these Terms.
            </p>

            <p>
               <strong> 3.5. </strong> You are responsible for maintaining the confidentiality of your Profile login
               information and are fully responsible for all activities that occur under your Profile. You agree to
               immediately notify the Company of any unauthorized use, or suspected unauthorized use of your Profile or
               any other breach of security. The Company cannot and will not be liable for any loss or damage arising
               from your failure to comply with the above requirements
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-4">
            <p class="title-paragraf">4. SERVICE</p>
            <p>
               <strong> 4.1</strong> You acknowledge that all the text, images, marks, logos, compilations (meaning the
               collection, arrangement and assembly of information), data, other content, software and materials
               displayed on the Service or used by the Company to operate the Service (including the App and the Content
               and excluding any User Content (as defined below)) is proprietary to us or to third parties.
            </p>
            <p>
               <strong>4.2</strong> The Company expressly reserves all rights, including all intellectual property
               rights, in all of the foregoing, and except as expressly permitted by these Terms, any use,
               redistribution, sale, decompilation, reverse engineering, disassembly, translation or other exploitation
               of them is strictly prohibited. The provision of the Service does not transfer to you or any third party
               any rights, title or interest in or to such intellectual property rights.
            </p>
            <p>
               <strong> 4.3.</strong> The information you submit to us as part of your registration, and any data, text
               and other material that you may submit or post to the App ("User Content") remain your intellectual
               property, and the Company does not claim any ownership of the copyright or other proprietary rights in
               such registration information and the User Content. Notwithstanding the foregoing, you agree that the
               Company may retain copies of all registration information and the User Content and use such information
               and the User Content as reasonably necessary for or incidental to its operation of the Service and as
               described in these Terms and the Privacy Policy.
            </p>
            <p>
               <strong> 4.4.</strong> You grant the Company the non-exclusive, worldwide, transferable, perpetual,
               irrevocable right to publish, distribute, publicly display and perform the User Content in connection
               with the Service.
            </p>

            <p>
               <strong> 4.5. </strong> Subject to these Terms, the Company grants you a non-transferable, non-exclusive,
               license (without the right to sublicense) to (i) use the Service solely for your personal, non-commercial
               purposes, and (b) install and use the App, solely on your own handheld mobile device (e.g., iPhone,
               Android, etc. as applicable) and solely for your personal, non-commercial purposes.
            </p>

            <p>
               <strong> 4.6. </strong>You agree, and represent and warrant, that your use of the Service, or any portion
               thereof, will be consistent with the foregoing license, covenants and restrictions and will neither
               infringe nor violate the rights of any other party or breach any contract or legal duty to any other
               parties. In addition, you agree that you will comply with all applicable laws, regulations and ordinances
               relating to the Service or your use of it, and you will be solely responsible for your own individual
               violations of any such laws.
            </p>

            <p>
               <strong> 4.7. </strong>You are solely responsible for obtaining the equipment and telecommunication
               services necessary to access the Service, and all fees associated therewith (such as computing devices
               and Internet service provider and airtime charges).
            </p>

            <p>
               <strong> 4.7. </strong> We retain the right to implement any changes to the Service (whether to free or
               paid features) at any time, with or without notice. You acknowledge that a variety of Company's actions
               may impair or prevent you from accessing the Service at certain times and/or in the same way, for limited
               periods or permanently, and agree that the Company has no responsibility or liability as a result of any
               such actions or results, including, without limitation, for the deletion of, or failure to make available
               to you, any content or services.
            </p>

            <p>
               <strong> 4.8. </strong> Your access to and use of the Service is at your own risk. The Company will have
               no responsibility for any harm to your computing system, loss of data, or other harm to you or any third
               party, including, without limitation, any bodily harm, that results from your access to or use of the
               Service, or reliance on any information or advice.
            </p>

            <p>
               <strong> 4.9. </strong> The Company has no obligation to provide you with customer support of any kind.
               However, the Company may provide you with customer support from time to time, at the Company's sole
               discretion.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-5">
            <p class="title-paragraf">5. APP STORES, THIRD PARTY ADS, OTHER USERS</p>
            <p>
               <strong> 5.1</strong> You acknowledge and agree that the availability of the App is dependent on the
               third party from which you received the App, e.g., the Apple App Store, and/or other app stores
               (collectively, "App Stores" and each, an "App Store").
            </p>
            <p>
               <strong>5.2</strong> You agree to pay all fees charged by the App Stores in connection with the App. You
               agree to comply with, and your license to use the App is conditioned upon your compliance with, all
               applicable agreements, terms of use/service, and other policies of the App Stores. You acknowledge that
               the App Stores (and their subsidiaries) are a third party beneficiary of these Terms and will have the
               right to enforce these Terms.
            </p>
            <p>
               <strong> 5.3.</strong> Each user of the Service is solely responsible for any and all his or her User
               Content. Because we do not control the User Content, you acknowledge and agree that we are not
               responsible for any User Content and we make no guarantees regarding the accuracy, currency, suitability,
               or quality of any User Content, and we assume no responsibility for any User Content. Your interactions
               with other Service users are solely between you and such user. You agree that the Company will not be
               responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute
            </p>
            <p>
               <strong> 5.4.</strong> You hereby release us, our officers, employees, agents and successors from claims,
               demands any and all losses, damages, rights, claims, and actions of any kind including personal injuries,
               death, and property damage, that is either directly or indirectly related to or arises from any
               interactions with or conduct of any App Store, any other Service users, or any Third Party Ads.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-6">
            <p class="title-paragraf">6. SUBSCRIPTION FEES AND PAYMENT</p>
            <p>
               <strong> 6.1</strong> Certain features of the Service may be offered on a subscription basis for a fee.
               You may purchase a subscription directly from the Company or through an App Store either by (1) paying a
               subscription fee in advance on a recurring interval disclosed to you prior to your purchase; or (2)
               pre-payment giving you access to the Service for a specific time period (together or separately
               “Purchase”).
            </p>
            <p>
               <strong>6.2</strong> To the maximum extent permitted by applicable laws, we may change Purchase fees at
               any time. We will give you reasonable notice of any such pricing changes by posting the new prices on or
               through the App and/or by sending you an e-mail notification, or in other prominent way. If you do not
               wish to pay the new fees, you can cancel the applicable subscription prior to the change going into
               effect or/and abstain from pre-paying for access to the Service.
            </p>
            <p>
               <strong> 6.3.</strong> You authorize us and the App Stores to charge the applicable fees to the payment
               card that you submit.
            </p>
            <p>
               <strong> 6.4.</strong> By signing up for certain subscriptions, you agree that your subscription may be
               automatically renewed. Unless you cancel your subscription you authorize us and the App Stores to charge
               you for the renewal term. The period of auto-renewal will be the same as your initial subscription period
               unless otherwise disclosed to you on the Service. The renewal rate will be no more than the rate for the
               immediately prior subscription period, excluding any promotional and discount pricing, unless we notify
               you of a rate change prior to your auto-renewal. You must cancel your subscription in accordance with the
               cancellation procedures disclosed to you for the particular subscription. We will not refund fees that
               may have accrued to your account and will not prorate fees for a cancelled subscription.
            </p>

            <p>
               <strong> 6.5. </strong> We may offer a trial subscription for the Service. Trial provides you access to
               the Service for a period of time, with details specified when you sign up for the offer. Unless you
               cancel before the end of the free trial, or unless otherwise stated, your access to the Service will
               automatically continue and you will be billed the applicable fees for the Service. It is ultimately your
               responsibility to know when the free trial will end. We reserve the right, in our absolute discretion, to
               modify or terminate any free trial offer, your access to the Service during the free trial, or any of
               these terms without notice and with no liability. We reserve the right to limit your ability to take
               advantage of multiple free trials.
            </p>

            <p>
               <strong> 6.6. </strong>The Service and your rights to use it expire at the end of the paid period of your
               subscription. If you do not pay the fees or charges due, we may make reasonable efforts to notify you and
               resolve the issue; however, we reserve the right to disable or terminate your access to the Service (and
               may do so without notice).
            </p>

            <p>
               <strong> 6.7. </strong>Subscriptions purchased via an App Store are subject to such App Store's refund
               policies. This means we cannot grant refunds. You will have to contact an App Store support.
            </p>

            <p>
               <strong> 6.7. </strong> Subject to clause 6.9 below, you agree that the Purchase is final, that Company
               will not refund any transaction once it has been made and that the Purchase cannot be canceled. When you
               make the Purchase, you acknowledge and agree that all Purchases are non-refundable or exchangeable.
               Notwithstanding anything to the contrary in the foregoing, the Company will provide refunds and/or
               Purchase cancellations in cases and to the extent required by mandatory provisions of the applicable law.
               The Company may also provide refunds at its own discretion and subject to our policies that may be
               published from time to
            </p>

            <p>
               <strong> 6.8. </strong> . If you are a consumer based in the EEA or Switzerland, you have an automatic
               legal right to withdraw from contracts for purchases of Services. However, when you make a purchase of a
               single item of digital content (such as a video recording or a pdf file) you expressly agree that such
               content is made available to you immediately and you, therefore, lose your right of withdrawal and will
               not be eligible for a refund. By signing up for our Service which is not a single item of digital content
               and is provided on a continuous basis (such as subscriptions to the App) you expressly request and
               consent to an immediate supply of such Service. Therefore, if you exercise your right of withdrawal we
               will deduct from your refund an amount that is in proportion to the Service provided before you
               communicated to us your withdrawal from the contract. - Exercise of the Right of Withdrawal. Where you
               have not lost your right of withdrawal, the withdrawal period will expire 14 days after the day you enter
               into that contract. To exercise your right of withdrawal, you must inform us - 00-079 Warsaw, Poland, ul.
               LWOWSKA, nr 5, lok. 15, miejsc. WARSZAWA, kod 00­660, poczta WARSZAWA, kraj POLSKA, Foundation Firma dla
               Każdego, email: support@sleep-well.coach- of your decision to withdraw from a contract by an unequivocal
               statement (e.g. a letter sent by post or e-mail). You may use the model withdrawal form below, but it is
               not obligatory. To meet the withdrawal deadline, you need to send your communication to us saying you
               wish to withdraw from the contract before the withdrawal period has expired. - Model Withdrawal Form To:
               00-079 Warsaw, Poland, ul. LWOWSKA, nr 5, lok. 15, miejsc. WARSZAWA, kod 00­660, poczta WARSZAWA, kraj
               POLSKA, Foundation Firma dla Każdego, email: support@sleep-well.coach I hereby give notice that I
               withdraw from my contract of the following service: Received on: Name: Address: Signature: (required only
               if sent by post mail) Date:
            </p>

            <p>
               <strong> 6.9. </strong> We may post clear and conspicuous subscription terms from time to time on our
               websites and within the Apps.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-7">
            <p class="title-paragraf">7. USER REPRESENTATIONS AND RESTRICTIONS</p>

            <p class="strong-paragraf-privacy">
               <strong>7.1.</strong> By using the Service, you represent and warrant that:
            </p>

            <ol class="lict-theere-privacy-seven">
               <li>you have the legal capacity and you agree to comply with these Terms;</li>
               <li>you are not under the age of 16;</li>
               <li>
                  you will not access the Service through automated or non-human means, whether through a bot, script or
                  otherwise;
               </li>
               <li>you will not use the Service for any illegal or unauthorized purpose;</li>
               <li>
                  you are not located in a country that is subject to a U.S. government embargo, or that has been
                  designated by the U.S. government as a "terrorist supporting" country;
               </li>
               <li>you are not listed on any U.S. government list of prohibited or restricted parties; and</li>
               <li>your use of the Service will not violate any applicable law or regulation.</li>
            </ol>
            <p>
               <strong> 7.2.</strong> If you provide any information that is untrue, inaccurate, not current, or
               incomplete, we have the right to refuse any and all current or future use of the Service (or any portion
               thereof).
            </p>
            <p>
               <strong> 7.3.</strong> You may not access or use the Service for any purpose other than that for which we
               make the Service available. The Service may not be used in connection with any commercial endeavors
               except those that are specifically authorized or approved by us.
            </p>
            <p><strong>7.4</strong> As a user of the Service, you agree not to:</p>
            <ol class="lict-theere-privacy-seven">
               <li>
                  systematically retrieve data or other content from the Service to create or compile, directly or
                  indirectly, a collection, compilation, database, or directory without written permission from us;
               </li>
               <li>make any unauthorized use of the Service;</li>
               <li>
                  make any modification, adaptation, improvement, enhancement, translation, or derivative work from the
                  Service;
               </li>
               <li>
                  use the Service for any revenue generating endeavor, commercial enterprise, or other purpose for which
                  it is not designed or intended;
               </li>
               <li>
                  make the Service available over a network or other environment permitting access or use by multiple
                  devices or users at the same time;
               </li>
               <li>
                  use the Service for creating a product, service, or software that is, directly or indirectly,
                  competitive with or in any way a substitute for the Service;
               </li>
               <li>
                  use any proprietary information or any of our interfaces or our other intellectual property in the
                  design, development, manufacture, licensing, or distribution of any applications, accessories, or
                  devices for use with the Service;
               </li>
               <li>circumvent, disable, or otherwise interfere with security-related features of the Service;</li>
               <li>engage in unauthorized framing of or linking to the Service;</li>
               <li>
                  interfere with, disrupt, or create an undue burden on the Service or the networks or services
                  connected to the Service;
               </li>
               <li>
                  decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way
                  making up a part of the Service;
               </li>
               <li>
                  attempt to bypass any measures of the Service designed to prevent or restrict access to the Service,
                  or any portion of the Service;
               </li>
               <li>
                  upload or distribute in any way files that contain viruses, worms, trojans, corrupted files, or any
                  other similar software or programs that may damage the operation of another's computer;
               </li>
               <li>
                  use, launch, develop, or distribute any automated system, including without limitation, any spider,
                  robot, cheat utility, scraper, or offline reader that accesses the Service, or using or launching any
                  unauthorized script or other software;
               </li>
               <li>
                  use the Service to send automated queries to any website or to send any unsolicited commercial e-mail;
               </li>
               <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Service;</li>
               <li>use the Service in a manner inconsistent with any applicable laws or regulations; or</li>
               <li>herwise infringe these Terms.</li>
            </ol>
         </div>
         <div class="paragraf-privacy" id="item-content-8">
            <p class="title-paragraf">8. ADDITIONAL DISCLAIMER OF WARRANTIES</p>
            <p><strong>a.</strong> Basic Disclaimers of Warranties.</p>
            <p class="toLowerCase">
               EXCEPT WHERE OTHERWISE INAPPLICABLE OR PROHIBITED BY LAW, YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR
               USE OF THE SERVICE IS AT YOUR SOLE RISK, AND THE SERVICE AND PRODUCTS ARE PROVIDED ON AN "AS IS" AND "AS
               AVAILABLE" BASIS. THE COMPANY OR ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS
               EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
               IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AS WELL AS
               ANY AND ALL WARRANTIES AS TO PRODUCTS OR SERVICES OFFERED BY BUSINESSES LISTED ON THE SERVICE.
            </p>

            <p class="strong-paragraf-privacy">
               In particular, the released parties make no, and expressly disclaim any warranty that:
            </p>

            <ol class="lict-theere-privacy-seven">
               <li>the Service will meet your requirements,</li>
               <li>the Service will be uninterrupted, timely, secure, or error-free,</li>
               <li>
                  the results that may be obtained from the use of the Service, including data, will be accurate or
                  reliable,
               </li>
               <li>the quality of any data or service available on the Service will meet your expectations, and</li>
               <li>any errors in the service will be corrected</li>
            </ol>

            <p>
               Any material obtained through the use of the Service is accessed at your own discretion and risk, and you
               will be solely responsible for any damage to your computer system or mobile device or loss of data that
               results from the use of any such material. We cannot guarantee and do no promise any specific results
               from use of the App and/or the Service. You agree also to take the risks of interruption of the Service
               for any technical reasons.
            </p>
            <p>
               <strong> b.</strong> Absence of Any Advice on the Service Any statement that may be posted on the Service
               is for informational and entertainment purposes only and is not intended to replace or substitute for any
               professional financial, medical, legal, or other advice. The Company makes no representations or
               warranties and, to the fullest extent permitted by law, expressly disclaims any and all liability
               relating to your reliance on the statements or other information offered or provided within or through
               the Service. If you have specific concerns or a situation arises in which you require professional or
               medical advice, you should consult with an appropriately trained and qualified specialist.
            </p>
            <p>
               <strong>c.</strong> Change of Website Information and Service We may change all the information provided
               on the Service at our sole discretion without notice. We may at any time modify or discontinue,
               temporarily or permanently, the Service (or any part thereof) at our sole discretion with or without
               notice. You agree that we shall not be liable to you or any third party for any modification, suspension
               or discontinuance of the Service.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-9">
            <p class="title-paragraf">9. LIMITATION OF LIABILITY</p>
            <p class="toLowerCase">
               <strong>9.1.</strong> IN NO EVENT SHALL WE (AND OUR AFFILIATES) BE LIABLE TO YOU OR ANY THIRD PARTY FOR
               ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES
               ARISING FROM THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SERVICE (INCLUDING THE APP OR CONTENT)
               AND PRODUCTS, OR THIRD PARTY ADS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS
               TO, AND USE OF, THE SERVICE (INCLUDING THE APP, CONTENT AND USER CONTENT), AND THIRD PARTY ADS ARE AT
               YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTING SYSTEM
               OR LOSS OF DATA RESULTING THEREFROM.
            </p>
            <p class="toLowerCase">
               <strong> 9.2</strong> NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, YOU AGREE THAT THE
               AGGREGATE LIABILITY OF THE COMPANY TO YOU FOR ANY AND ALL CLAIMS ARISING FROM THE USE OF THE APP,
               CONTENT, SERVICE OR PRODUCTS, IS LIMITED TO THE AMOUNTS YOU HAVE PAID TO THE COMPANY FOR ACCESS TO AND
               USE OF THE SERVICE. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
               THE TERMS BETWEEN THE COMPANY AND YOU.
            </p>

            <p class="toLowerCase">
               <strong> 9.3</strong> IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION
               1542, WHICH STATES, “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY
               DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF
               KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED
               PARTY.”
            </p>
            <p class="toLowerCase">
               <strong> 9.4</strong> SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
               INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU
               MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-10">
            <p class="title-paragraf">10. INDEMNITY</p>

            <p>
               You agree to indemnify and hold the Company, its successors, subsidiaries, affiliates, any related
               companies, its suppliers, licensors and partners, and the officers, directors, employees, agents and
               representatives of each of them harmless, including costs and attorneys' fees, from any claim or demand
               made by any third party due to or arising out of (i) your use of the Service or Products, (ii) your User
               Content, or (ii) your violation of these Terms.
            </p>

            <p>
               The Company reserves the right, at your expense, to assume the exclusive defense and control of any
               matter for which you are required to indemnify us and you agree to cooperate with our defense of these
               claims. You agree not to settle any matter without the prior written consent of the Company. The Company
               will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of
               it.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-11">
            <p class="title-paragraf">11. INTERNATIONAL USE</p>

            <p>
               The Company makes no representation that the Service is accessible, appropriate or legally available for
               use in your jurisdiction, and accessing and using the Service is prohibited from territories where doing
               so would be illegal. You access the Service at your own initiative and are responsible for compliance
               with local laws.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-12">
            <p class="title-paragraf">12. MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</p>

            <p>
               PLEASE READ THIS ARBITRATION PROVISION CAREFULLY TO UNDERSTAND YOUR RIGHTS. IT REQUIRES YOU TO ARBITRATE
               DISPUTES WITH US AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US, IN PARTICULAR:
            </p>

            <ol class="lict-theere-privacy-seven">
               <li>All claims must be resolved through binding arbitration by a neutral arbitrator;</li>
               <li>
                  You are waiving the right to a trial by jury; the rights that you would have if you went to court,
                  such as discovery or the right to appeal, may be more limited or may not exist;
               </li>
               <li>
                  You may only bring a claim in your individual capacity and not as a plaintiff (lead or otherwise) or
                  class member in any purported class or representative proceeding;
               </li>
               <li>
                  The arbitrator may not consolidate proceedings or claims or otherwise preside over any form of a
                  representative or class proceeding.
               </li>
            </ol>
            <p><strong>a.Applicability of Arbitration Agreement</strong></p>
            <p>
               This arbitration agreement governs any dispute between you and the company (and each of our respective
               agents, corporate parents, subsidiaries, affiliates, predecessors in interest, successors, and assigns)
               including but not limited to claims arising out of or relating to any aspect of the relationship between
               you and the Company, whether based in contract, tort, statute, fraud, misrepresentation or any other
               legal theory; claims that arose before these Terms or any prior agreement; and claims that may arise
               after the termination of these Terms (“Dispute”), except claims that can be brought in small claims court
               if your claims qualify within the scope of that court's jurisdiction.
            </p>
            <p>
               Without limiting the preceding sentence, you will also have the right to litigate any other Dispute if
               you opt out of this arbitration and class action waiver provisions by sending electronic notice of your
               decision to opt out to support@sleep-well.coach with the subject line, "ARBITRATION AND CLASS ACTION
               WAIVER OPT-OUT" within 30 days of (a) the effective date of these Terms; or (b) your first date that you
               used the Service that contained any versions of the Terms that substantially included this version of the
               Arbitration Agreement (including class action waiver), whichever is later. If you opt out of this
               Arbitration Agreement, the Company also will not be bound by it and any Dispute shall be resolved in
               accordance with Section 13. If you don’t exercise the right to opt out, you will be deemed to have
               knowingly and intentionally waived your right to litigate any Dispute except claims that can be brought
               in small claims court.
            </p>
            <p>
               This Arbitration Agreement shall apply, without limitation, to all claims that arose or were asserted
               before the effective date of these Terms or any prior version of these Terms.
            </p>
            <p>
               The relevant arbitrator shall have sole authority to determine applicability, existence, validity and
               termination of the Arbitration Agreement in each particular case. In the event that a dispute involves
               both issues that are subject to arbitration and issues that are not subject to arbitration, the parties
               unequivocally agree that any legal proceeding regarding the issues not subject to arbitration shall be
               stayed pending resolution of the issues subject to arbitration.
            </p>
            <p><strong>b.Initial Dispute Resolution</strong></p>
            <p>
               We are always interested in resolving disputes amicably and efficiently. If you have any dispute with the
               Company, you agree that before taking any formal action, you will contact us at support@sleep-well.coach,
               and provide a brief, written description of the dispute and your contact information. The parties agree
               to use their best efforts to settle any dispute, claim, question, or disagreement directly through
               consultation with the Company, and good faith negotiations will be a condition to either party initiating
               an arbitration.
            </p>
            <p><strong>c. Mandatory Arbitrationn</strong></p>
            <p>
               This arbitration agreement provides that all Disputes must be resolved through BINDING ARBITRATION,
               except to the extent that the applicable law prohibits the exclusive use of arbitration for dispute
               resolution.
            </p>
            <p>
               YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND WE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR
               TO PARTICIPATE IN A CLASS ACTION AND AGREE TO HAVE OUR DISPUTES FINALLY SETTLED BY BINDING ARBITRATION
               before one arbitrator administered by:
            </p>

            <ol class="lict-theere-privacy-seven">
               <li class="ul-line-height">
                  the London Court of International Arbitration (“LCIA”) if you are not a U.S. resident. Disputes are
                  subject to the most current version of the LCIA Arbitration Rules when the notice of arbitration is
                  submitted. Information about the LCIA’s rules can be found at
                  <a href="https://www.lcia.org/Dispute_Resolution_Services/LCIA_Arbitration.aspx"
                     >https://www.lcia.org/Dispute_Resolution<br
                        class="linkSplittin"
                     />_Services/LCIA_Arbitration.aspx</a
                  >
                  or
               </li>
               <li class="ul-line-height">
                  Judicial Arbitration and Mediation Services, Inc. (“JAMS”) if you are a U.S. resident. Disputes
                  involving claims and counterclaims under USD 250,000, not inclusive of attorneys’ fees and interest,
                  shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures
                  available at
                  <a href="http://www.jamsadr.com/rules-streamlined-arbitration/"
                     >http://www.jamsadr.com/rules-streamlined-arbitration/</a
                  >; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration
                  Rules and Procedures, available at
                  <a href="http://www.jamsadr.com/rules-comprehensive-arbitration/"
                     >http://www.jamsadr.com/rules-comprehensive-arbitration/</a
                  >. JAMS’s rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267.
               </li>
            </ol>
            <p>
               In each case the relevant arbitration rules will apply as modified by this Arbitration Agreement. In the
               event of a conflict between the applicable arbitration rules and these Terms, these Terms shall govern
               unless otherwise agreed by the parties and the relevant arbitrator. If the relevant administrator of
               arbitration is not available to arbitrate, the parties will select an alternative arbitral forum.
            </p>
            <p><strong>d. Waiver of Class Action and Collective Relief</strong></p>
            <p class="toLowerCase">
               THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE ARBITRATED OR LITIGATED ON A CLASS ACTION,
               JOINT OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY
               ON BEHALF OF THE GENERAL PUBLIC, OTHER USERS OF THE SERVICES, OR ANY OTHER PERSONS. THE ARBITRATOR MAY
               AWARD RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
               PROVIDE RELIEF WARRANTED BY THAT INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT AWARD RELIEF FOR OR
               AGAINST ANYONE WHO IS NOT A PARTY. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND
               MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. THIS WAIVER OF CLASS
               ACTIONS AND COLLECTIVE RELIEF IS AN ESSENTIAL PART OF THIS ARBITRATION PROVISION AND CANNOT BE SEVERED
               FROM IT.
            </p>
            <p class="toLowerCase">
               BY AGREEING TO THE ARBITRATION OF DISPUTES AS SET FORTH HEREIN, YOU AGREE THAT YOU ARE WAIVING YOUR RIGHT
               TO A JURY TRIAL AND LIMITING YOUR RIGHT TO APPEAL AND YOU UNDERSTAND THAT YOU ARE WAIVING YOUR RIGHTS TO
               OTHER AVAILABLE RESOLUTION PROCESSES, SUCH AS A COURT ACTION.
            </p>
            <p>THE ARBITRATOR HAS NO AUTHORITY TO AWARD PUNITIVE DAMAGES.</p>
            <p><strong>e. Arbitration Procedures</strong></p>
            <p>
               - Overview. Arbitration is an alternative to litigation where a neutral person (the arbitrator) hears and
               decides the parties’ Dispute. Arbitration proceedings are designed to provide parties with a fair hearing
               in a manner that is faster and less formal than court proceedings. The following procedures (the
               “Arbitration Procedures”) are applicable to all arbitration proceedings involving you and us.
            </p>
            <p>- Seat of Arbitration. The seat of the arbitration shall be:</p>
            <ol class="lict-theere-privacy-seven">
               <li>if you are not a U.S. resident, London, United Kingdom;</li>
               <li>2. if you are a U.S. resident, Delaware, U.S.</li>
            </ol>
            <p>
               - Choice of Law. The governing law applicable to the arbitration agreement and the arbitration shall be:
            </p>
            <ol class="lict-theere-privacy-seven">
               <li>
                  if you are not a U.S. resident, the laws of England and Wales (also known as English Law), without
                  regard to English Law’s conflict of laws rules; or
               </li>
               <li>
                  if you are a U.S. resident, Delaware law consistent with the Federal Arbitration Act and applicable
                  statutes of limitations, having regard to claims of privilege recognized at law.
               </li>
            </ol>
            <p>- Language. The language of the arbitration shall be English.</p>
            <p>- Commencing an Arbitration. To start an arbitration, you must follow instructions available at:</p>
            <ol class="lict-theere-privacy-seven">
               <li>
                  <a
                     href="https://www.lcia.org/adr-services/lcia-notes-for-parties.aspx#5.%20COMMENCING%20AN%20LCIA%20ARBITRATION"
                  >
                     1.https://www.lcia.org/adr-services/lcia-notes-for-parties.aspx#5.%20COMMENCING%20<br
                        class="linkSplittin"
                     />AN%20LCIA%20ARBITRATION for LCIA;</a
                  >or
               </li>
               <li>
                  <a href="https://www.jamsadr.com/submit/"> 2.https://www.jamsadr.com/submit/ for JAMS.</a>
               </li>
            </ol>
            <p>
               You may represent yourself in the arbitration or have a lawyer (or some other representative) act on your
               behalf. Upon receipt of an arbitration claim, we may assert any counterclaims we may have against the
               complaining party.
            </p>
            <p>
               - Fees. If you are a consumer and you initiate arbitration against us, the only filing fee you will be
               required to pay is USD 250 and the rest of the filing fees (if any) shall be borne by us. If the
               arbitrator finds the arbitration initiated by you to be non-frivolous and/or not in bad faith we will
               cover all other arbitration costs, including case management fees and all professional fees for the
               arbitrator's services (but not your attorneys’ fees, if any).
            </p>
            <p>
               If we initiate arbitration against you and you are a consumer, we will pay for all costs associated with
               the arbitration (but not your attorneys’ fees, if any).
            </p>
            <p>
               The parties shall be responsible for paying their own attorneys’ fees unless the arbitration rules and/or
               applicable law provide otherwise.
            </p>
            <p>
               Should either party bring a Dispute involving issues subject to arbitration in a forum other than
               arbitration, the court or the arbitrator shall have the authority to award reasonable costs, fees and
               expenses, including reasonable attorneys’ fees, incurred by the other party in successfully staying or
               dismissing, in whole or in part, such other proceeding or in otherwise enforcing compliance with this
               Arbitration Agreement.
            </p>
            <p>
               - Selection of the Arbitrator. The arbitrator who will hear and decide your Dispute will be appointed by
               the LCIA or JAMS, as applicable, in accordance with their respective rules.
            </p>
            <p>
               Arbitration Hearings. The arbitrator will conduct hearings, if any, by teleconference or videoconference
               (based on written and/or electronic filing of documents), rather than by personal appearances, unless the
               arbitrator determines upon request by you or by us that an in-person hearing is appropriate. Any
               in-person appearances will be held at a location which is reasonably convenient to both parties with due
               consideration of their ability to travel and other pertinent circumstances, provided that if you are a
               consumer, you have a right to an in-person hearing in your hometown area. If the parties are unable to
               agree on a location, such determination should be made by the administrator of arbitration or by the
               arbitrator.
            </p>
            <p>
               - Discovery. Each party may (a) request relevant, non-privileged documents from the other party; and (b)
               request that the other party provide the particulars of its claims or defenses. Any such discovery
               requests must be served on the other party within 10 days after the arbitrator’s appointment. The
               responding party shall provide the requesting party with all responsive, non-privileged documents, the
               requested particulars, and/or any objections to the requests within 15 days after receipt of the
               requests. Any disputes about discovery or requests for extensions shall be submitted promptly to the
               arbitrator for prompt resolution. In ruling on any discovery dispute or extension request, the arbitrator
               shall take into consideration the nature, amount, and scope of the underlying arbitration claim, the cost
               and other effort what would be involved in providing the requested discovery, the case schedule, and
               whether the requested discovery is necessary for the adequate preparation of a claim or defense.
            </p>
            <p>
               - Communications with the Arbitrator. Whenever communicating with the arbitrator, the parties must
               include each other – for example, by including the other party on a telephone conference call and copying
               the other party on any written submissions, such as letters or emails. To the extent practicable,
               conferences with the arbitrator will take place by telephone conference call or email. Ex parte
               communications are not permitted with any arbitrator.
            </p>
            <p>
               - Confidentiality. Upon either party’s request, the arbitrator will issue an order requiring that
               confidential information of either party disclosed during the arbitration (whether in documents or
               orally) may not be used or disclosed except in connection with the arbitration or a proceeding to enforce
               the arbitration award and that any permitted filing of confidential information must be done under seal.
            </p>
            <p>
               - Arbitration Award. The arbitrator will render a written decision within 14 days after the hearing or,
               if no hearing was held, within 30 days after any rebuttal or supplemental statements are due. The
               decision must clearly specify the relief, if any, awarded and contain a brief statement of the reasons
               for the award.
            </p>
            <p>
               - Waiver of Appeal. The parties agree that the award shall be final and binding upon the parties and
               waive any right to refer any question of law and any right of appeal on the law and/or the merits to any
               court.
            </p>
            <p>
               - Consumer Remedies. If you are a consumer, remedies that would otherwise be available to you under
               applicable laws will remain available under this Arbitration Agreement, unless you retain the right to
               pursue such remedies in court as per this Agreement.
            </p>
            <p><strong>f. Severability of Arbitration Agreement</strong></p>
            <p>
               If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any reason, (a)
               the unenforceable or unlawful provision shall be severed from these Terms; (b) severance of the
               unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this Arbitration
               Agreement or the parties’ ability to compel arbitration of any remaining claims on an individual basis
               pursuant to this Arbitration Agreement; and (c) to the extent that any claims must therefore proceed on a
               class, collective, consolidated, or representative basis, such claims must be litigated in court in
               accordance with Section 14, and the parties agree that litigation of those claims shall be stayed pending
               the outcome of any individual claims in arbitration. Further, if any part of this Arbitration Agreement
               is found to prohibit an individual from seeking the remedy of public injunctive relief, that provision
               will have no effect to the extent such relief is allowed to be sought out of arbitration, and the
               remainder of this Arbitration Agreement will be enforceable.
            </p>
            <p><strong>g. Survival</strong></p>
            <p>This arbitration provision shall survive termination of this Terms.</p>
         </div>
         <div class="paragraf-privacy" id="item-content-13">
            <p class="title-paragraf">13. GOVERNING LAW AND VENUE</p>

            <p>
               <strong>13.1</strong>These Terms shall be governed in accordance with the laws of England and Wales
               (excluding its body of law governing conflicts of law).
            </p>
            <p>
               <strong>13.2</strong>To the extent that any action relating to any dispute hereunder is for whatever
               reason not submitted to arbitration, each of the parties submits to the exclusive jurisdiction to the
               courts of England and Wales to settle any disputes which may arise out of or in connection with this
               Terms and that accordingly proceedings must be brought in such courts.
            </p>
            <p>
               <strong>13.3</strong>The parties irrevocably submit to the personal jurisdiction and venue of the courts
               of England and waive any defenses of improper venue or forum non conveniens.
            </p>
            <p><strong>13.4</strong>T. If you are a resident of the European Union:</p>
            <p>
               Nothing in these Terms shall deprive you of the protection afforded to consumers by the mandatory rules
               of law of the country in which you live.
            </p>
            <p>
               If you have a complaint, please contact us at <a href="mailto:support@sleep-well.coach."></a> If you feel
               your complaint is not adequately addressed you may – but are not obliged to – use the Online Dispute
               Resolution (ODR) platform that you can access through
               <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr.</a> Other than as set out in the Terms,
               BetterMe does not participate in any alternative dispute resolution scheme.
            </p>
            <p>
               You may bring any dispute which may arise under these Terms and Conditions to the competent court of your
               country of habitual residence if this country of habitual residence is an EU Member State, which courts
               are – with the exclusion of any other court - competent to settle any of such a dispute. BetterMe shall
               bring any dispute which may arise under these Terms and Conditions to the competent court of your country
               of habitual residence.
            </p>
            <p>
               You agree that the Services, Terms, and any dispute between you and BetterMe shall be governed in all
               respects by laws of England and Wales, without regard to choice of law provisions, and not by the 1980 UN
               Convention on Contracts for the International Sale of Goods.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-14">
            <p class="title-paragraf">14. MISCELLANEOUS PROVISIONS</p>

            <p>
               <strong>14.1 </strong> No delay or omission by us in exercising any of our rights occurring upon any
               noncompliance or default by you with respect to these Terms will impair any such right or be construed to
               be a waiver thereof, and a waiver by the Company of any of the covenants, conditions or agreements to be
               performed by you will not be construed to be a waiver of any succeeding breach thereof or of any other
               covenant, condition or agreement hereof contained.
            </p>
            <p>
               <strong>14.2 </strong> Subject to Section 12, if any provision of these Terms is found to be invalid or
               unenforceable, then these Terms will remain in full force and effect and will be reformed to be valid and
               enforceable
            </p>
            <p>
               <strong>14.3 </strong> Except as otherwise expressly provided herein, these Terms set forth the entire
               agreement between you and the Company regarding its subject matter, and supersede all prior promises,
               agreements or representations, whether written or oral, regarding such subject matter.
            </p>
            <p>
               <strong>14.4 </strong> The Company may transfer or assign any and all of its rights and obligations under
               these Terms to any other person, by any way, including by novation, and by accepting these Terms you give
               the Company consent to any such assignment and transfer. You confirm that placing on the Service of a
               version of these Terms indicating another person as a party to the Terms shall constitute valid notice to
               you of the transfer of Company's rights and obligations under the Terms (unless otherwise is expressly
               indicated).
            </p>
            <p>
               <strong>14.5 </strong> All information communicated on the Service is considered an electronic
               communication. When you communicate with us through or on the Service or via other forms of electronic
               media, such as e-mail, you are communicating with us electronically. You agree that we may communicate
               electronically with you and that such communications, as well as notices, disclosures, agreements, and
               other communications that we provide to you electronically, are equivalent to communications in writing
               and shall have the same force and effect as if they were in writing and signed by the party sending the
               communication. You further acknowledge and agree that by clicking on a button labeled "SUBMIT",
               "CONTINUE", "REGISTER", "I AGREE" or similar links or buttons, you are submitting a legally binding
               electronic signature and are entering into a legally binding contract. You acknowledge that your
               electronic submissions constitute your agreement and intent to be bound by these Terms. YOU HEREBY AGREE
               TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS AND TO ELECTRONIC DELIVERY OF
               NOTICES, POLICIES AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE SERVICE.
            </p>
            <p>
               <strong>14.6 </strong> In no event shall the Company be liable for any failure to comply with these Terms
               to the extent that such failure arises from factors outside the Company's reasonable control.
            </p>
         </div>
         <div class="paragraf-privacy" id="item-content-15">
            <p class="title-paragraf">15. CONTACT</p>

            <p>
               If you want to send any notice under these Terms or have any questions regarding the Service, you may
               contact us at: <a href="support@sleep-well.coach">support@sleep-well.coach</a>.
            </p>
            <p class="toLowerCase">I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.</p>

            <p>D8-CREW s.r.o., Oděská 1573, CZ-75661 Rožnov pod Radhoštěm, Czechia</p>
            <p>Identification number 19563582, Tax ID (VAT) CZ19563582</p>
            <p>Last Updated: 01 May 2024</p>
         </div>
      </div>
   </div>
</template>

<script>
import { SWNavButton } from 'sw-ui-kit';

export default {
   components: {
      SWNavButton,
   },
   data() {
      return {
         hasBackPage: false,
      };
   },
   mounted() {
      this.anchorHashCheck();
   },
   computed: {
      isDarkTheme() {
         return JSON.parse(localStorage.getItem('theme')) === true;
      },
   },
   methods: {
      anchorHashCheck() {
         //console.log(window.location.hash);
         if (window.location.hash) {
            const el = document.getElementById(window.location.hash.slice(1));
            if (el) {
               window.scrollTo(0, el.offsetTop);
            }
         }
      },
   },
   beforeRouteEnter(to, from, next) {
      next(vm => {
         vm.hasBackPage = !!from.name;
      });
   },
};
</script>

<style lang="sass" scoped>
.toLowerCase
   text-transform: lowercase
.ul-line-height
   line-height: 28px
   padding-bottom: 15px
.content-link
   font-size: 22px
   font-weight: 500
.contents-list-item
   font-size: 18px
   margin-bottom: 20px
   font-weight: 400
.content-link
   font-size: 20px
.container-privacy
   width: 100%
   height: 100%
   margin: 0 auto
   max-width: 1246px
.text-content-privacy
   max-width: 870px
   padding-bottom: 50px
   margin: 0 auto
   font-size: 16px
   font-weight: 400
   margin-top: 40px
.btn-nav-ragister
   margin-bottom: 20px
   font-weight: 400
.title-privacy
    text-align: center
    font-weight: 700
    font-size: 32px
    line-height: 41.6px
    margin-bottom: 40px
.paragraf-privacy
    margin-bottom: 40px
.paragraf-privacy p
    margin-bottom: 20px
.title-paragraf
    font-size: 20px
    font-weight: 500
    line-height: 26px
.lict-one-privacy li
    padding-bottom: 16px

.lict-two-privacy
    line-height: 20.8px
    list-style: none
    margin-left: 0
    padding-left: 0
.lict-two-privacy li
    padding-bottom: 16px
.strong-paragraf-privacy
    font-weight: 500
.lict-theere-privacy
    line-height: 20.8px
    list-style: lower-latin
    margin-left: 17px
    padding-left: 0
.lict-theere-privacy li
    padding-bottom: 16px

.lict-theere-privacy-seven
   line-height: 20.8px
   margin-left: 65px
   padding-left: 0
.lict-theere-privacy-seven li
    padding-bottom: 16px
.corrent
    display: block
.container-privacy p
   line-height: 30px
.linkSplittin
   display: none

.dark-theme
   color: white

@media screen and (max-width:1024px)
   .text-content-privacy
      margin-top: 40px
   .btn-nav-ragister
      margin-top: 16px
      transform: translateX(-14px)

@media screen and (max-width:420px)
   .linkSplittin
      display: block
</style>
